import React from "react"
import "../../styles/cta-boxes.scss"

export default function CtaBoxes({ data, slug, location }) {
    return (
        <div className="cta-boxes-wrapper">
            <div className="cta-boxes">
                {data && data?.map((box) => {
                    return (
                        <div className="cta-box">
                            <img data-sal="fade"
                                 data-sal-delay="100"
                                 data-sal-duration="1s"
                                 data-sal-easing="ease-in" src={box?.image?.localFile?.publicURL} alt={box?.image?.altText} loading="lazy" />
                            <a data-sal="fade"
                               data-sal-duration="1s"
                               data-sal-easing="ease" className="button" href={box?.button?.url}>{box?.button?.title}</a>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
