import React, {useState} from "react"
import "../../styles/content-right-with-toggle.scss"

export default function ContentRightWithToggle({ data, slug, location }) {
    var openToggle = false
    const[toggle, setToggle] = useState(openToggle)

    function switchToggle() {
        openToggle = toggle
        openToggle = !openToggle
        return setToggle(openToggle)
    }

    return (
        <div className="content-right-with-toggle-wrapper">
            <div className="box-wrapper">
                <div className="box">
                    <img data-sal="fade"
                         data-sal-delay="100"
                         data-sal-duration="1s"
                         data-sal-easing="ease-in" src={data?.image?.localFile?.publicURL} alt={data?.image?.altText} loading="lazy" />
                    <div className="text-content-wrapper">
                        <div data-sal="fade"
                             data-sal-delay="100"
                             data-sal-duration="1s"
                             data-sal-easing="ease-in" className={"description " + (slug ? "city-description" : '')} dangerouslySetInnerHTML={{__html: data?.contentAboveToggle}}></div>
                        {data?.toggle?.title &&
                        <div data-sal="fade"
                             data-sal-delay="100"
                             data-sal-duration="1s"
                             data-sal-easing="ease-in" className="toggle-wrapper">
                            <h2 className={"title title__toggle " + (toggle ? 'minus' : '')} onClick={() => switchToggle()}>{data?.toggle?.title}</h2>
                            <div className={"description description__toggle " + (toggle ? 'opened' : '')} dangerouslySetInnerHTML={{__html: data?.toggle?.content}}></div>
                        </div>
                        }
                        <div data-sal="fade"
                             data-sal-delay="100"
                             data-sal-duration="1s"
                             data-sal-easing="ease-in" className="description" dangerouslySetInnerHTML={{__html: data?.contentBelowToggle}}></div>
                        <div data-sal="fade"
                             data-sal-delay="100"
                             data-sal-duration="1s"
                             data-sal-easing="ease-in" className="links-wrapper">
                            {data?.linksBelowToggle && data?.linksBelowToggle.map((link) => {
                                return (
                                    <a className="text-link" href={link?.link?.url}>{link?.link?.title}</a>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className="after-box-content">
                <div data-sal="fade"
                     data-sal-delay="100"
                     data-sal-duration="1s"
                     data-sal-easing="ease-in" className="description description-large" dangerouslySetInnerHTML={{__html: data?.contentBelowBox?.content}}></div>
                <a data-sal="fade"
                   data-sal-duration="1s"
                   data-sal-easing="ease" className="button" href={data?.contentBelowBox?.button?.url}>{data?.contentBelowBox?.button?.title}</a>
            </div>
        </div>
    )
}
