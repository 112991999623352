import React from "react"
import "../../styles/text-content-with-logo.scss"

export default function TextContentWithLogo({ data, slug, location }) {
    return (
        <div className="text-content-with-logo-wrapper">
            <div className="logo-wrapper">
                <img data-sal="fade"
                     data-sal-delay="100"
                     data-sal-duration="1s"
                     data-sal-easing="ease-in" className="background-image" src={data?.backgroundLogoImage?.localFile?.publicURL}
                    alt={data?.backgroundLogoImage?.altText} />
                <img data-sal="fade"
                     data-sal-delay="100"
                     data-sal-duration="1s"
                     data-sal-easing="ease-in" className="logo-image" src={data?.logo?.localFile?.publicURL}
                    alt={data?.logo?.altText}
                    loading="lazy" />
            </div>
            <div className="text-content-wrapper">
                <h2 data-sal="fade"
                    data-sal-delay="100"
                    data-sal-duration="1s"
                    data-sal-easing="ease-in" className="title">{data?.title}</h2>
                <div data-sal="fade"
                     data-sal-delay="100"
                     data-sal-duration="1s"
                     data-sal-easing="ease-in" className="description" dangerouslySetInnerHTML={{__html: data?.content}}></div>
            </div>
        </div>
    )
}
